import React, { Fragment, useEffect, useState } from "react";
import Main, { client } from "../components/main";
import SEO from "../components/section/seo";
import PortalHeader from "../components/portal/portal-header";
import { useMutation, useQuery } from "@apollo/client";
import { getCurrentUserQuery, getUserTOTP } from "../components/logic/user";
import Loader from "../components/bootstrap/loader";
import { Button, Col, Container, Row } from "react-bootstrap";
import authLogo from "../images/assets/icon_authent.svg"
import authOnLogo from "../images/assets/icon_authenticator on.svg"
import qrScanImage from "../images/assets/icon_scan.svg"
import Modal from "../components/bootstrap/modal";
import { createVerificationMutation, generateTOTPMutation, removeUserTOTPMutation } from "../components/logic/verification";
import QRCode from "qrcode";
import { getOperationName } from "@apollo/client/utilities";
import {BottomLeft, BottomRight, TopLeft, TopRight} from "./../components/portal/corners";

const LinkButton = (props) => {
  const {title, icon, className} = props;
  return (
    <div className="mfa-link">
      <button {...props}>
        <span className={`link-label ${className}` }>{title} <i className={`${icon} pl-2`}/></span>
      </button>
    </div>
    
  )
}

export default function ProfileTOTP(props) {
  const {apiUrl} = props;
  const [generateTOTP] = useMutation(generateTOTPMutation);
  const [createVerification] = useMutation(createVerificationMutation);
  const [removeUserTOTP] = useMutation(removeUserTOTPMutation);

  const [showQR , setshowQR] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [showDisableTOTP, setShowDisableTOTP] = useState(false);
  const [qrCode , setQRCode] = useState(false);
  
  const [requestCode , setRequestCode] = useState(null);
  const [otp, setOTP] = useState(Array(6).fill(""));
  const [otpError, setOTPError] = useState(null);


  const [inputFocus, setInputFocus] = useState(null);


  const currentUserQuery = useQuery(getCurrentUserQuery);
  if (currentUserQuery.loading) {
    return <Loader />;
  }

  const user = currentUserQuery?.data?.classMethods?.User?.getCurrentUser;

  if (!user) {
    window.location = "/login";
  }
  
  const generate = () => {
    generateTOTP({
      variables: {id: user.id}
    })
    .then(response=>{
      console.log(response)
      const result = response.data?.classMethods?.UserAuth?.generateTOTP;
      console.log(result)
      if (result && result.otpAuthURL) {
        QRCode.toDataURL(result.otpAuthURL, (err, data)=>{
          setQRCode(data);
          setshowQR(true)
        })
      }
      
    })
  }

  const requestVerification = () => {
    createVerification({
      variables: {
        type: "TEMP_TOTP",
        userId: user?.id,
        name: "temp totp",
      },
      awaitRefetchQueries: true,
    }).then(result => {
      const {data} = result;
      console.log("create result", data)
      setRequestCode(data?.classMethods?.Verification?.createVerification?.id);
      setshowQR(false);
      setShowOTP(true);
    });
  }

  const activateOTP = async () => {
    const response = await fetch(`${apiUrl}rest.api/verify/${requestCode}:${otp.join("")}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    });
    const data = await response.json();
    console.log(data)
    if (data.success) {
      await currentUserQuery.refetch();
      setShowOTP(false);
    } else {
      setOTPError("Invalid code");
    }
  }

  const disableTOTP = async () => {
    removeUserTOTP({
      variables: {},
      awaitRefetchQueries: true,
      refetchQueries: [getOperationName(getCurrentUserQuery)],
    }).then(result => {
      const {data} = result;
      setShowDisableTOTP(false);
    });
  }
  
  return (
    <div className="mfa-totp">
      <PortalHeader />
      <Container className="vw-portal-account-title">
        {`Welcome ${user.firstName} ${user.lastName}!`}
      </Container>
      <Container className="mfa-panel-container">
        <Container className="mfa-panel-body">
            <Row className="mfa-help">
              <span className="mfa-label">{user.hasUserTOTP? "Authentication is on" :"Help protect your account"}</span>
              <p className="mfa-description">
                {"if we notice an attempted login from a device we don't recognize, we'll ask for your password and a verification code."}
              </p>
            </Row>
              <hr className="divider"/>
            <Row className="mfa-app">
              <Col xs={12} lg={3}>
                <img src={user.hasUserTOTP? authOnLogo : authLogo} alt="menu"/>
              </Col>
              <Col xs={12} lg={9} >
                <span className="mfa-label">{"Authentication App"}</span>
                {user.hasUserTOTP && <p className="mfa-description">{"You'll receive a login code via an authenticator app"}</p>}
                {!user.hasUserTOTP && <p className="mfa-description">
                  <span className="green-text">{"Recommended"}</span>
                  {" • Use an app like Google Authenticator, Mircrosoft Authenticator, or Authy to generate verification codes for more protection."}
                </p>}
                
                {!user.hasUserTOTP && <LinkButton title="Setup 2-step verification" icon="fas fa-angle-right" onClick={()=>generate()}/>}
                {user.hasUserTOTP && <LinkButton title="Disable authentication app" icon="fas fa-angle-right" onClick={()=>setShowDisableTOTP(true)}/>}
                
                {showQR && <ScanQR onClose={()=>setshowQR(false)} qrCode={qrCode} sendRequest={requestVerification}/>}
                {showOTP && <OTPVerification 
                  requestCode={requestCode} 
                  otp={otp} 
                  setOTP={setOTP} 
                  otpError={otpError}
                  setOTPError={setOTPError} 
                  setInputFocus={setInputFocus} 
                  inputFocus={inputFocus}
                  onActivate={activateOTP}
                />}
                {showDisableTOTP && <DisableTOTP onClose={()=>setShowDisableTOTP(false)} disableTOTP={disableTOTP}/>}
              </Col>
            </Row>
        </Container>
      </Container>
    </div>
  )
}

const ScanQR = (props) => {
  const {onClose, qrCode, sendRequest} = props;
  return (
    <Modal
      centered
      size={"md"}
      show
      onClose={async () => onClose()}
    >
    <div className="totp-qr">
    <img src={qrScanImage} alt="menu" className="qrlogo"/>
    <snap className="label pt-4">Scan QR code</snap>
    <p className="description">
      {"Use an authenticator app from your phone to scan."}
      <br/>
      {"After you scan the QR code, choose Next."}
    </p>
    <div className="vw-authenticator-body">
      <TopLeft/>
      <TopRight/>
      <BottomLeft/>
      <BottomRight/>
      <div className="container vw-authenticator-container d-flex flex-column text-align-center justify-content-center">
        <img src={qrCode} alt="no-image-found" className="qr-displayed text-center" />
      </div>
    </div>
    <div className="action-group">
      <LinkButton className="link-btn-black" title="Back" onClick={()=>onClose()}/>
      <LinkButton title="Next" icon="fas fa-angle-right" onClick={sendRequest}/>
    </div>
    </div>
  </Modal>
  )
}

const OTPVerification = (props) => {
  const {otp, setOTP, otpError, setOTPError, setInputFocus, inputFocus , onActivate, onClose} = props;

  useEffect(() => {
    const element = document.getElementById(inputFocus);

    if (element?.value) {
      element.select();
    } else {
      element?.focus();
    }
  }, [inputFocus]);
  return (
    <Modal
    centered
    size={"md"}
    show
    onClose={() => onClose()}
  >
    <div className="totp-otp">
      <span className="label">{"Enter confirmation code"}</span>
      <p className="description">{"please enter the confirmation code you see on your authentication app."}</p>
     <OtpInput count={6} value={otp} onChange={value => setOTP(value)} setOtpError={setOTPError} setInputFocus={setInputFocus}/>
     {otpError && <div className="totp-otp-input-error">{otpError}</div>}
     <div className="px-5 ml-auto">
      <LinkButton title="Activate" icon="fas fa-angle-right" onClick={()=>onActivate()}/>
     </div>
    </div>
  </Modal>
  )
}


const OtpInput = ({count = 1, value, onChange, setOtpError, setInputFocus}) => {
  return (
    <div className="totp-otp-input-container">
      {Array(count).fill("").map((_, index) => {
        return (
          <input
            key={index}
            id={`otp-input-${index}`}
            value={value[index]}
            maxLength={1}
            onChange={e => {
              setOtpError(null);
              const pattern = /\D/gim;
              const activeElement = document.activeElement;

              if (!pattern.test(e.target.value)) {
                value[index] = e.target.value;
                onChange([...value]);
                if (activeElement) {
                  const nextElementId = activeElement.id.slice(0, activeElement.id.lastIndexOf("-") + 1) + (index + 1);
                  console.log(nextElementId)
                  setInputFocus(nextElementId);
                }
              }
              if(e.target.value === "") {
                if (activeElement) {
                  const nextElementId = activeElement.id.slice(0, activeElement.id.lastIndexOf("-") + 1) + (index - 1);
                  setInputFocus(nextElementId);
                }
              }
            }}
            onClick={e => {
              setInputFocus(e.target.id);
            }}
            onKeyDown={e => {
              const activeElement = document.activeElement;
              if (e.shiftKey && e.code === "Tab" && activeElement) {
                e.preventDefault();
                const lastDashIndex = activeElement.id.lastIndexOf("-");
                const previousElementId = activeElement.id.slice(0, lastDashIndex + 1) + (Number(activeElement.id.slice(lastDashIndex + 1)) - 1);
                setInputFocus(previousElementId);
              }
            }}
          />
        );
      })}
    </div>
  );
};


const DisableTOTP = (props) => {
  const {onClose, disableTOTP} = props;
  return (
    <Modal
      centered
      size={"md"}
      show
      onClose={async () => onClose()}
      footer={(
      <Container>
        <Row>
          <Col xs="auto ml-auto" className="cancel-col">
            <Button
              className="cancel-request-btn"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                return onClose();
              }}
            >
              {"Cancel"}
            </Button>
          </Col>
          <Col xs="auto">
            <Button
              className="submit-request-button"
              type="submit"
              onClick={() => disableTOTP()}
            >
              {"Disable"}
            </Button>
          </Col>
        </Row>
      </Container>)}
    >
      <div>{"Are you sure you want to disable authenticator app?"}</div>
  </Modal>
  )
}